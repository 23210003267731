import If from 'components/If';
import { Role } from 'interfaces';
import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IApi } from './API';
import AutoComplete, { AutoCompleteEntry } from 'components/Autocomplete'
import Translation from './Language/Translation';
import { useConsistentState } from 'component_utils/utils';
import { FaSearch } from 'react-icons/fa';
import classNames from 'classnames';
import { ScannerFocusTrigger } from 'components/Scanner';
import { isString } from 'lodash';

export let addPrompt: (prompt: PromptComponent, props?: any) => Promise<any> = () => {
  throw Error('not initialized');
};

export const confirmSuperUserAllow = async (api: IApi, text: ReactNode, requiredRoles: Role[]) => {
  const admin = await addPrompt(({ index, id, resolve, afterClosed }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [state, setState] = useState({ username: '', password: '' });

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const ok = useCallback(() => {
      resolve(state);
      setIsOpen(false);
    }, [resolve, state]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-input-name`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.confirmSuperUserClearance" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            <Alert color="danger">{text}</Alert>
            <br />
            <FormGroup floating>
              <Label for={`prompts-${id}-input-name`}>
                <Translation name="T.login.username" />
              </Label>
              <Input
                autoComplete="off"
                id={`prompts-${id}-input-name`}
                type="text"
                value={state.username}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !e.repeat) {
                    e.preventDefault();
                    document.getElementById(`prompts-${id}-input-pass`).focus();
                  }
                }}
                onChange={(e) => setState({ ...state, username: e.target.value })}
              />
            </FormGroup>
            <FormGroup floating>
              <Label for={`prompts-${id}-input-pass`}>
                <Translation name="T.login.password" />
              </Label>
              <Input
                autoComplete="off"
                id={`prompts-${id}-input-pass`}
                type="password"
                value={state.password}
                onChange={(e) => setState({ ...state, password: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !e.repeat) {
                    e.preventDefault();
                    document.getElementById(`prompts-${id}-btn-ok`).click();
                  }
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            id={`prompts-${id}-btn-cancel`}
            className="w-25"
            color="secondary"
            onClick={cancel}
          >
            <Translation name="T.misc.cancel" />
          </Button>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

  if (!admin) {
    return Promise.reject('T.errors.noManagerLoggedIn');
  }
  return await api.verifyManagerUser({
    username: admin.username,
    password: admin.password,
    requiredRoles,
  });
};

export let alert = (text: ReactNode): Promise<boolean> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [isOpen, setIsOpen] = useState(true);

    const ok = useCallback(() => {
      resolve(true);
      setIsOpen(false);
    }, [resolve]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-btn-ok`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.alert" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {text}
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

export let confirm = (text: ReactNode): Promise<boolean> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [isOpen, setIsOpen] = useState(true);

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const ok = useCallback(() => {
      resolve(true);
      setIsOpen(false);
    }, [resolve]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-btn-ok`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.confirm" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {text}
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            id={`prompts-${id}-btn-cancel`}
            className="w-25"
            color="secondary"
            onClick={cancel}
          >
            <Translation name="T.misc.cancel" />
          </Button>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

export let prompt = (text: ReactNode, def: string, allowCancel: boolean = true): Promise<string> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [state, setState] = useState(def);
    const [isOpen, setIsOpen] = useState(true);

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const ok = useCallback(() => {
      resolve(state);
      setIsOpen(false);
    }, [resolve, state]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-input-confirm`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.prompt" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {text}
            <br />
            <Input id={`prompts-${id}-input-confirm`} value={state} onChange={(e) => setState(e.target.value)} />
          </Form>
        </ModalBody>

        <ModalFooter>
          <If cond={allowCancel}>
            <Button
              id={`prompts-${id}-btn-cancel`}
              className="w-25"
              color="secondary"
              onClick={cancel}
            >
              <Translation name="T.misc.cancel" />
            </Button>
          </If>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

export let choice = (text: ReactNode, def: string, choices: (string|(string[]))[], allowCancel: boolean = true): Promise<string> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [state, setState] = useState(def);
    const [isOpen, setIsOpen] = useState(true);

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const ok = useCallback(() => {
      resolve(state);
      setIsOpen(false);
    }, [resolve, state]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-input-choice`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.prompt" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {text}
            <br />
            <Input
              type='select'
              id={`prompts-${id}-input-choice`} 
              value={state} 
              onChange={(e) => setState(e.target.value)}
            >
              {choices.map(it => (
                isString(it) ? (
                  <option key={it} value={it}>{it}</option>
                ) : (
                  <option key={it[0]} value={it[0]}>{it[1]}</option>
                )
              ))}
            </Input>
          </Form>
        </ModalBody>

        <ModalFooter>
          <If cond={allowCancel}>
            <Button
              id={`prompts-${id}-btn-cancel`}
              className="w-25"
              color="secondary"
              onClick={cancel}
            >
              <Translation name="T.misc.cancel" />
            </Button>
          </If>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

export let choiceList = <T,>(text: ReactNode, choices: { label: ReactNode, value: T }[], allowCancel: boolean = true, fullScreen: boolean = true): Promise<T> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [isOpen, setIsOpen] = useState(true);

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const select = useCallback((v: T) => {
      resolve(v);
      setIsOpen(false);
    }, [resolve]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onClosed={afterClosed}
        className={fullScreen && 'custom-modal flex-container'}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.prompt" />
        </ModalHeader>

        <ModalBody className={fullScreen && 'flex-fill-height custom-modal-body'}>
          <Form className='flex-container' onSubmit={(e) => { e.preventDefault(); }} >
            <div>
              {text}
            </div>
            <div className='nested-flex-container' style={{ overflowY: 'scroll' }}>
              {choices.map((it, index) => (
                <Button
                  className="button_large"
                  color="primary"
                  block
                  onClick={() => {
                    select(it.value)
                  }}
                  key={index}
                >
                  {it.label}
                </Button>
              ))}
            </div>
          </Form>
        </ModalBody>

        <ModalFooter>
          <If cond={allowCancel}>
            <Button
              id={`prompts-${id}-btn-cancel`}
              className="w-25"
              color="secondary"
              onClick={cancel}
            >
              <Translation name="T.misc.cancel" />
            </Button>
          </If>
        </ModalFooter>
      </Modal>
    );
  });

export let promptAutocomplete = (text: ReactNode, def: string, autoComplete: (s: string) => Promise<AutoCompleteEntry[]>, allowCancel: boolean = true, showQuickSearch: boolean = false): Promise<string> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [state, stateRef, setState] = useConsistentState(def);
    const [isOpen, setIsOpen] = useState(true);

    const cancel = useCallback(() => {
      resolve(null);
      setIsOpen(false);
    }, [resolve]);

    const ok = useCallback(() => {
      resolve(stateRef.current);
      setIsOpen(false);
    }, [resolve, stateRef]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`prompts-${id}-input-confirm`).focus()}
        onClosed={afterClosed}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          <Translation name="T.prompts.prompt" />
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {text}
            <br />
            <AutoComplete fetchAutocompleteOptions={autoComplete} onSelect={s => {
              setState(s)
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}>
              {({ ref, onBlur, onKeyDownHandler, onKeyUpHandler, triggerSearch }) => (
                <InputGroup>
                  <Input
                    innerRef={ref}
                    onKeyDown={onKeyDownHandler}
                    onKeyUp={onKeyUpHandler}
                    onBlur={onBlur}
                    id={`prompts-${id}-input-confirm`}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                  {showQuickSearch && (
                    <InputGroupAddon addonType='append'>
                      <Button onClick={triggerSearch}><FaSearch/></Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              )}
            </AutoComplete>
          </Form>
        </ModalBody>

        <ModalFooter>
          <If cond={allowCancel}>
            <Button
              id={`prompts-${id}-btn-cancel`}
              className="w-25"
              color="secondary"
              onClick={cancel}
            >
              <Translation name="T.misc.cancel" />
            </Button>
          </If>
          <Button
            id={`prompts-${id}-btn-ok`}
            autoFocus
            className="w-25"
            color="primary"
            onClick={ok}
          >
            <Translation name="T.misc.ok" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  });

export let customPrompt = (
  content: (idPref: string, proceed: (v: any) => any) => ReactElement, 
  buttons: (idPref: string, proceed: (v: any) => any) => ReactElement[],
  title: ReactNode, 
  focusOnId: string,
  fullscreen: boolean
): Promise<any> =>
  addPrompt(({ index, id, resolve, afterClosed }) => {
    const [isOpen, setIsOpen] = useState(true);
    const idPref = `prompts-${id}-`

    const ok = useCallback((v: any) => {
      resolve(v);
      setIsOpen(false);
    }, [resolve]);

    return (
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onOpened={() => document.getElementById(`${idPref}${focusOnId}`)?.focus()}
        onClosed={afterClosed}
        className={classNames({
          'custom-modal custom-modal-full-width flex-container': fullscreen,
        })}
      >
        <ScannerFocusTrigger/>
        <ModalHeader>
          {title}
        </ModalHeader>

        <ModalBody
          className={classNames({
            'flex-fill-height custom-modal-body': fullscreen,
          })}        
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              document.getElementById(`prompts-${id}-btn-ok`).click();
            }}
          >
            {content(idPref, ok)}
          </Form>
        </ModalBody>

        <ModalFooter>
          {buttons(idPref, ok).map((b, index) => React.cloneElement(b, { 
            key: index, 
          }))}
        </ModalFooter>
      </Modal>
    );
  });



export type PromptComponent = React.ComponentType<{
  index: number;
  id: number;
  resolve: (value: any) => void;
  afterClosed: () => void;
  userProps: any;
}>;
interface Prompt {
  id: number;
  resolve: (value: any | PromiseLike<any>) => void;
  Component: PromptComponent;
  props: any;
}

export default ({ children }: any) => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  addPrompt = (prompt: PromptComponent, props: any) => {
    return new Promise((resolve, reject) => {
      setPrompts((old) => [
        ...old,
        {
          id: Date.now(),
          resolve,
          Component: prompt,
          props: props || {},
        },
      ]);
    });
  };

  return (
    <>
      {children}

      {prompts.map((it, index) => {
        const C = it.Component;
        return (
          <C
            key={it.id}
            index={index}
            id={it.id}
            resolve={it.resolve}
            afterClosed={() => {
              setPrompts((old) => old.filter((f) => f.id !== it.id));
            }}
            userProps={it.props}
          />
        );
      })}
    </>
  );
};
